<template>
  <div id="User">
    <!-- 组织架构 用户-->

    <el-dialog title="用户授权"
               :visible.sync="isShowUserRole">
      <el-transfer :titles="['未选择', '已选择']"
                   v-model="transferValue"
                   :data="transferData"
                   @change="changeTransferUserOFRole"></el-transfer>
    </el-dialog>
    <!-- Dialog 对话框 -->
    <el-dialog :title="openType === 'add' ? '新增用户' : '编辑用户'"
               :visible.sync="isShowDialogVisible">
      <CommonForm :form="openForm"
                  :formLabel="openFormLabel"></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="cancelPowerForm">取 消</el-button>
        <el-button type="primary"
                   @click="subPowerForm(openType, openForm)">确 定</el-button>
      </div>
    </el-dialog>

    <CommonManageMain>
      <template v-slot:header>
        <div>
          <el-button type="primary"
                     size="small"
                     @click="clickAddUser"
                     icon="el-icon-circle-plus-outline">增加</el-button>
          <el-button type="danger"
                     size="small"
                     @click="clickDelUser"
                     icon="el-icon-delete">删除</el-button>
        </div>

        <CommonForm :form="searchForm"
                    :formLabel="searchformLabel"
                    inline>
          <el-button type="primary">搜索</el-button>
        </CommonForm>
      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <CommonTable :tableData="tableData"
                     :tableLabel="tableLabel"
                     :tableConfig="tableConfig"
                     @changeSwitch="changeSwitch"
                     style="height:680px">
          <el-table-column label="操作"
                           width="150">
            <template slot-scope="scope">
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="编辑"
                          placement="top">
                <el-button type="primary"
                           size="mini"
                           icon="el-icon-edit"
                           @click="clickEditUser(scope.row)"></el-button>
              </el-tooltip>

              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="关联角色"
                          placement="top">
                <el-button type="success"
                           size="mini"
                           icon="el-icon-star-off"
                           @click="clickUserOFRole(scope.row)"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </CommonTable>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>
import {
  metRoleList,
  getUserList,
  methUserData,
  postUserStateData,
  getUserOfRoleData,
  postUserAuthorizeRoleData
} from 'api/organize.js'
import CommonForm from 'components/CommonForm.vue'
import CommonTable from 'components/CommonTable.vue'
import CommonManageMain from 'components/CommonManageMain.vue'

export default {
  components: {
    CommonForm,
    CommonTable,
    CommonManageMain
  },

  data () {
    return {
      rowId: '',
      transferValue: [],
      transferData: [],
      // 对话框 Dialog 基础数据
      openType: 'add',
      isShowDialogVisible: false,
      isShowUserRole: false,
      // form表单 基础数据
      openForm: {
        avatar: '',
        name: '',
        phone: '',
        password: '',
        email: '',
        sex: ''
      },
      // clues 提示语
      openFormLabel: [
        {
          model: 'avatar',
          label: '头像',
          type: 'upload',
          clues: '只能上传jpg/png文件，且格式为80x80'
        },
        {
          model: 'name',
          label: '姓名'
        },
        {
          model: 'phone',
          label: '电话'
        },
        {
          model: 'password',
          label: '密码',
          type: 'password'
        },
        {
          model: 'email',
          label: '邮箱'
        },
        {
          model: 'sex',
          label: '性别',
          type: 'radio',
          options: [
            // value:'值',label:'标签'
            { value: 0, label: '女' },
            { value: 1, label: '男' }
          ]
        }
      ],

      // form表单 搜索数据
      searchForm: {
        keyword: ''
      },
      searchformLabel: [
        {
          model: 'keyword',
          label: '名字',
          labelOFF: true,
          options: []
        }
      ],

      // table 表格 基础数据
      tableData: [],
      tableLabel: [
        {
          prop: 'name',
          label: '姓名'
        },
        {
          prop: 'phone',
          label: '电话'
        },
        {
          prop: 'email',
          label: '邮箱'
        },
        {
          prop: 'sex',
          label: '性别',
          type: 'sex'
        },
        {
          prop: 'state',
          label: '状态',
          type: 'state'
        },
        {
          prop: 'cDate',
          label: '创建时间'
        }
      ],
      tableConfig: {
        isShowLoading: true,
        // 改造状态列显示效果 el-switch

        page: 1,
        total: 30,
        // 加载状态
        loading: false,
        // 显示选择多行数据
        selection: true
      },
      userListData: [],
      // 角色数组
      roleListData: []
    }
  },

  mounted () {
    this.getTableData()
    this.getRoleList()
  },

  watch: {
    // 监听 userListData 数据发生改变
    userListData: function () {
      this.tableData = this.dataFormatDeal(
        this.userListData,
        {
          create_time: 'cDate',
          status: 'state'
        },
        'state'
      )
    }
  },

  methods: {
    // 用户---获取list数据
    getTableData () {
      this.tableConfig.loading = true
      getUserList().then(res => {
        if (res.data.code == 200) {
          this.userListData = res.data.data.list

          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.tableConfig.loading = false
        }
      })
    },
    // 编辑，添加 用户信息--提交到后台
    methUserData (method, args) {
      methUserData(method, args).then(res => {
        if (res.data.code == 200) {
          this.getTableData()
        }
      })
    },

    // 角色---获取list数据
    getRoleList () {
      metRoleList().then(res => {
        // name: "CEO"
        // description: "首席执行官"
        // status: 1
        this.roleListData = res.data.data.list
        console.log(this.roleListData)
      })
    },

    // 数据格式处理

    // id: 2
    // username: "18311110000"
    // name: "测试"
    // phone: "18311110000"
    // email: "text@163.com"
    // sex: 1
    // status: 1
    // create_time: "2021-05-20 14:15:16"
    // avatar: "http://xxx.com/test.png"
    // last_login_time: ""
    // 改成---------------------------------------
    // name 名字
    // phone  电话
    // email  邮箱
    // cDate 创建时间
    //  sex 性别
    // state  状态

    dataFormatDeal (arr, keyMap, bool) {
      for (const key in keyMap) {
        arr.reduce((prev, cur) => {
          cur[keyMap[key]] = cur[key]
          // 判断key的值为0,1，转成布尔类型
          if (keyMap[key] == bool) {
            cur[keyMap[key]] == '0'
              ? (cur[keyMap[key]] = false)
              : (cur[keyMap[key]] = true)
          }

          delete cur[key]
          prev.push(cur)

          return prev
        }, [])
      }
      return arr
    },

    // 取消提交按钮
    cancelPowerForm () {
      this.isShowDialogVisible = false
      // 用于当前 Vue 实例的初始化选项
      this.openForm = this.$options.data().openForm
    },
    // 确定提交按钮
    subPowerForm (type, val) {
      type == 'add'
        ? this.methUserData('POST', val)
        : this.methUserData('PUT', val)
      this.cancelPowerForm()
    },

    // 编辑用户
    clickEditUser (row) {
      //  avatar: "",
      //   name: "",
      //   phone: "",
      //   password: "",
      //   email: "",
      //   sex: ""
      for (const item in this.openFormLabel) {
        const openFormKey = this.openFormLabel[item].model
        this.openForm[openFormKey] = eval('row.' + openFormKey)
      }
      this.openForm.id = row.id
      this.openType = 'edit'
      this.isShowDialogVisible = true
    },
    // 用户 授权 角色 -- 设置
    clickUserOFRole (row) {
      // data:{ key: i,
      //       label:''}
      // value:[key1,key2]
      this.rowId = row.id

      const userId = {}
      userId.admin_user_id = row.id

      console.log('用户角色 -- 设置', row)

      this.isShowUserRole = true
      getUserOfRoleData(userId).then(res => {
        console.log(res)
        let mapArr = []
        if (res.data.code == 200) {
          // hold
          // this.transferValue.push(
          const holdId = res.data.data.map(item => {
            if (item.hold == 1) return item.id
          })

          mapArr = res.data.data.map(item => {
            return {
              key: item.id,
              label: item.name + '_' + item.description
            }
          })
          this.transferData = mapArr
          this.transferValue = holdId.filter(d => d)
          console.log(
            ' this.transferValue',
            holdId.filter(d => d)
          )
        }
      })
    },
    changeTransferUserOFRole (value, direction, movedKeys) {
      // admin_user_id	是	int	管理员ID
      // role_id	是	arr	角色ID [2,4]

      const dataObj = {}
      dataObj.admin_user_id = this.rowId
      dataObj.role_id = value.length == 0 ? [''] : value
      console.log('dataObj', dataObj)
      postUserAuthorizeRoleData(dataObj).then(res => {
        console.log('changeTransferUserOFRole', res)
      })
    },
    // 新增用户
    clickAddUser () {
      this.openType = 'add'
      this.isShowDialogVisible = true
    },
    // 删除用户
    clickDelUser () {
      console.log('删除用户')
    },
    // 关闭 dialog 显示  清空数据
    shutdownOpenForm () {
      this.isShowDialogVisible = false
      // 清空对象中的val值
      for (const item in this.openForm) {
        this.openForm[item] = ''
      }
    },
    // 表格 开关状态切换
    changeSwitch (row) {
      const obj = {}
      obj.id = row.id
      row.state == true ? (obj.status = 'open') : (obj.status = 'close')
      postUserStateData(obj).then(res => {
        if (res.data.code == 200) {
          // this.$message({
          //   message: res.message,
          //   type: "success"
          // });
          this.getTableData()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#User {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;
  .el-transfer {
    text-align: center;
    /deep/ .el-transfer-panel {
      text-align: left;
    }
  }
}
</style>
